import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  Paper,
} from "@mui/material";
import FilterComponent from "./components/FiltersStorageTabel.component";
import ChannelSalesDialog from "./components/ChannelSales.component";

const TabelaCustomizada = () => {
  const maxOC = 2500;
  const maxEstoque = 10000;
  const [showFilters, setShowFilters] = useState(false);
  const [estoqueAtual, setEstoqueAtual] = useState({ min: 0, max: 10000 });
  const [estoqueOC, setEstoqueOC] = useState({ min: 0, max: 2500 });
  const [filterText, setFilterText] = useState("");
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const mockData = [
    {
      codigo: "MOR02002",
      deposito: "Geral",
      descricao: "Cadeira de Praia Alta em Aço 110 kg Cores Variadas Mor",
      fat90d: 2905,
      fat30d: 856,
      canais: {
        "Mercado Livre": 100,
        Amazon: 168,
        Shopee: 150,
        "Magazine Luiza": 50,
      },
      estoqueAtual: 1232,
      ultimaVenda: "16/01/25",
      diasUltimaVenda: 7,
      custoUn: 42.98,
      diasEstoque: 38.2,
      valorEstoque: 52951.36,
      emOC: 2250,
      diasEstoqueMaisOC: 107.9,
      preco: 0,
    },
    {
      codigo: "MOR060105",
      deposito: "Geral",
      descricao: "Varal de Chão com Abas Retrátil Slim Mor",
      fat90d: 1637,
      fat30d: 468,
      canais: {
        "Mercado Livre": 100,
        Amazon: 168,
        Shopee: 150,
        "Magazine Luiza": 50,
      },
      estoqueAtual: 786,
      ultimaVenda: "15/01/25",
      diasUltimaVenda: 14,
      custoUn: 59.92,
      diasEstoque: 43.2,
      valorEstoque: 47097.12,
      emOC: 600,
      diasEstoqueMaisOC: 76.2,
      preco: 0,
    },
    {
      codigo: "TRA23198018",
      deposito: "Geral",
      descricao: "Faqueiro Tramontina 20 peças Cores Variadas",
      fat90d: 290,
      fat30d: 88,
      canais: {
        "Mercado Livre": 100,
        Amazon: 168,
        Shopee: 150,
        "Magazine Luiza": 50,
      },
      estoqueAtual: 800,
      ultimaVenda: "01/01/25",
      diasUltimaVenda: 60,
      custoUn: 29.59,
      diasEstoque: 88.1,
      valorEstoque: 0,
      emOC: 1500,
      diasEstoqueMaisOC: 88.1,
      preco: 0,
    },
    {
      codigo: "MOR02101",
      deposito: "Geral",
      descricao: "Cadeira de Praia em Alumínio Cores Variadas",
      fat90d: 491,
      fat30d: 36,
      canais: {
        "Mercado Livre": 100,
        Amazon: 168,
        Shopee: 150,
        "Magazine Luiza": 50,
      },
      estoqueAtual: 390,
      ultimaVenda: "16/12/24",
      diasUltimaVenda: 30,
      custoUn: 44.31,
      diasEstoque: 77,
      valorEstoque: 17552.1,
      emOC: 360,
      diasEstoqueMaisOC: 100,
      preco: 0,
    },
    {
      codigo: "MOR08701",
      deposito: "Geral",
      descricao: "Escorredor de louça preto",
      fat90d: 491,
      fat30d: 36,
      canais: {
        "Mercado Livre": 100,
        Amazon: 168,
        Shopee: 150,
        "Magazine Luiza": 50,
      },
      estoqueAtual: 0,
      ultimaVenda: "16/12/24",
      diasUltimaVenda: 30,
      custoUn: 44.31,
      diasEstoque: 77,
      valorEstoque: 17552.1,
      emOC: 0,
      diasEstoqueMaisOC: 100,
      preco: 0,
    },
    {
      codigo: "MOR08701",
      deposito: "Geral",
      descricao: "Escorredor de louça preto",
      fat90d: 491,
      fat30d: 36,
      canais: {
        "Mercado Livre": 100,
        Amazon: 168,
        Shopee: 150,
        "Magazine Luiza": 50,
      },
      estoqueAtual: 50,
      ultimaVenda: "16/12/24",
      diasUltimaVenda: 90,
      custoUn: 44.31,
      diasEstoque: 77,
      valorEstoque: 17552.1,
      emOC: 90,
      diasEstoqueMaisOC: 100,
      preco: 0,
    },
  ];
  const [filteredData, setFilteredData] = useState(mockData);

  const getBackgroundColor = (diasUltimaVenda) => {
    if (diasUltimaVenda <= 7) return "white";
    if (diasUltimaVenda >= 8 && diasUltimaVenda <= 14) return "#f2f2f2"; // Cinza claro
    if (diasUltimaVenda >= 15 && diasUltimaVenda <= 30) return "#fffccf"; // Amarelo claro
    if (diasUltimaVenda >= 31 && diasUltimaVenda <= 60) return "#ffd59c"; // Laranja claro
    return "#f8a1a1"; // Vermelho claro
  };

  const canaisVenda = [
    { label: "Mercado Livre" },
    { label: "Amazon" },
    { label: "Shopee" },
    { label: "Magazine Luiza" },
  ];

  const handleResetFilters = () => {
    setEstoqueAtual({ min: 0, max: 10000 });
    setEstoqueOC({ min: 0, max: 2500 });
    setFilterText("");
    setSelectedChannel(null);
  };

  useEffect(() => {
    const filtered = mockData.filter((item) => {
      const matchesText = Object.values(item).some((field) =>
        String(field).toLowerCase().includes(filterText.toLowerCase())
      );
      const withinEstoqueAtual =
        item.estoqueAtual >= estoqueAtual.min &&
        item.estoqueAtual <= estoqueAtual.max;
      const withinEstoqueOC =
        item.emOC >= estoqueOC.min && item.emOC <= estoqueOC.max;

      // Verifica se o canal selecionado corresponde ao item
      const matchesChannel =
        !selectedChannel ||
        (item.canal && item.canal === selectedChannel.label);

      return (
        matchesText && withinEstoqueAtual && withinEstoqueOC && matchesChannel
      );
    });
    setFilteredData(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, estoqueAtual, estoqueOC, selectedChannel]);

  const handleViewChannels = (item) => {
    setSelectedProduct(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedProduct(null);
  };
  return (
    <>
      <Box>
        <FilterComponent
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          estoqueAtual={estoqueAtual}
          estoqueOC={estoqueOC}
          setEstoqueAtual={setEstoqueAtual}
          setEstoqueOC={setEstoqueOC}
          filterText={filterText}
          setFilterText={setFilterText}
          handleResetFilters={handleResetFilters}
          canaisVenda={canaisVenda}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  "Código",
                  "Depósito",
                  "Descrição",
                  "Fat90d",
                  "Fat30d",
                  "Estoque Atual",
                  "Última Venda",
                  "Dias Última Venda",
                  "Custo Un",
                  "Dias Estoque",
                  "R$ Estoque",
                  "Em OC",
                  "Dias Estoque + OC",
                  "Prog",
                ].map((header) => (
                  <TableCell key={header} align="center">
                    <Typography variant="body2" fontWeight="bold">
                      {header}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((item, index) => (
                <TableRow
                  sx={{
                    backgroundColor:
                      item.estoqueAtual === 0 ? "#F8A1A1" : "inherit",
                  }}
                  key={index}
                >
                  <TableCell align="center" style={{ padding: 4 }}>
                    {item.codigo}
                  </TableCell>
                  <TableCell align="center" style={{ padding: 4 }}>
                    {item.deposito}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ padding: 4, maxWidth: "200px" }}
                  >
                    <Tooltip title={item.descricao} arrow>
                      <Typography
                        noWrap
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.descricao}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center" style={{ padding: 4 }}>
                    {item.fat90d}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ padding: 4, cursor: "pointer" }}
                    onClick={() => handleViewChannels(item)}
                  >
                    {item.fat30d}
                  </TableCell>
                  <TableCell align="center" style={{ padding: 4 }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          maxWidth: "250px",
                          height: "20px",
                          borderRadius: "5px",
                          backgroundColor: "#f5f5f5",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            width: `${(item.emOC / maxEstoque) * 100}%`,
                            backgroundColor: "#FF947C",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 1,
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            {item.estoqueAtual}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      padding: 4,
                      backgroundColor: getBackgroundColor(item.diasUltimaVenda),
                    }}
                  >
                    {item.ultimaVenda}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      padding: 4,
                      backgroundColor: getBackgroundColor(item.diasUltimaVenda),
                    }}
                  >
                    {item.diasUltimaVenda}
                  </TableCell>
                  <TableCell align="center" style={{ padding: 4 }}>
                    R$ {item.custoUn.toFixed(2)}
                  </TableCell>
                  <TableCell align="center" style={{ padding: 4 }}>
                    {item.diasEstoque}
                  </TableCell>
                  <TableCell align="center" style={{ padding: 4 }}>
                    R$ {item.valorEstoque.toFixed(2)}
                  </TableCell>
                  <TableCell align="center" style={{ padding: 4 }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          maxWidth: "250px",
                          height: "20px",
                          borderRadius: "5px",
                          backgroundColor: "#f5f5f5",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            width: `${(item.emOC / maxOC) * 100}%`,
                            backgroundColor: "#FF947C",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 1,
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            {item.emOC}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell align="center" style={{ padding: 4 }}>
                    {item.diasEstoqueMaisOC}
                  </TableCell>
                  <TableCell align="center" style={{ padding: 4 }}>
                    {item.preco}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ChannelSalesDialog
        open={openDialog}
        onClose={handleCloseDialog}
        product={selectedProduct}
      />
    </>
  );
};

export default TabelaCustomizada;
