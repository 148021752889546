import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HomeIcon from "@mui/icons-material/Home";
import PersonAddAlt1TwoToneIcon from "@mui/icons-material/PersonAddAlt1TwoTone";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import UsersPage from "../pages/Users/Users.page";
import HomePage from "../pages/Home/Home.page";
import Settings from "@mui/icons-material/SettingsOutlined";
import AddBusiness from "@mui/icons-material/AddBusinessOutlined";
import EcomercePage from "../pages/Configurações/Ecomerce.page";
import OrdersPage from "../pages/Orders/Orders.page";
import FornecedoresPage from "../pages/Configurações/Fornecedores.page";
import PurchaseOrder from "../pages/Orders/PurchaseOrder.page";
import StoragePage from "../pages/Orders/Storage.page";

const Menus = [
  {
    segment: "/dashboard",
    title: "Dashboard",
    icon: <HomeIcon />,
    component: <HomePage />,
  },
  {
    segment: "",
    title: "Pedidos",
    icon: <ShoppingCartIcon />,
    children: [
      {
        segment: "",
        title: "Analise mensal",
        icon: <DonutSmallOutlinedIcon />,
        component: <OrdersPage />,
      },
      {
        segment: "",
        title: "Ordem de compra",
        icon: <AddShoppingCartIcon />,
        component: <PurchaseOrder />,
      },
      {
        segment: "",
        title: "Estoque",
        icon: <Inventory2OutlinedIcon />,
        component: <StoragePage />,
      },
    ],
  },
  {
    segment: "",
    title: "Clientes",
    icon: <PersonAddAlt1TwoToneIcon />,
    component: <UsersPage />,
  },
  {
    segment: "",
    title: "Configurações",
    icon: <Settings />,
    children: [
      {
        segment: "",
        title: "Ecomerce",
        icon: <AddBusiness />,
        component: <EcomercePage />,
      },
      {
        segment: "",
        title: "Fornecedores",
        icon: <ContactMailIcon />,
        component: <FornecedoresPage />,
      },
    ],
  },
];

export { Menus };
