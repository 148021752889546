import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const estadosBrasil = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

const StatesSelectComponent = ({
  label = "Estado",
  value,
  onChange,
  required = false,
  size = "small",
  error,
  helperText,
}) => {
  return (
    <Autocomplete
      options={estadosBrasil}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="dense"
          fullWidth
          required={required}
          size={size}
          error={error}
          helperText={helperText}
        />
      )}
      value={value || null}
      onChange={(event, newValue) => onChange(newValue)}
    />
  );
};

export default StatesSelectComponent;
