import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function TokenDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Como obter seu token:
          </Typography>
          <IconButton>
            <CloseOutlinedIcon />
          </IconButton>
        </Box>

        <List sx={{ mb: 2 }}>
          <ListItem>
            <Box>
              <ListItemText primary="1. Acesse o link abaixo para acessar a plataforma(faça login se necessário)." />
              <Button
                href="https://app.anymarket.com.br/login.html#/login/log"
                target="_blank"
                rel="noreferrer"
              >
                Clique aqui para acessar
              </Button>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <ListItemText primary="2. Clique no nome do usuário logado (chip no canto superior direito)." />
              <img
                src={require("../../../assets/passo_1.png")}
                width={800}
                alt="Token"
              />
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <ListItemText primary="3. Selecione 'Dados da Organização' no menu que será aberto(segunda opção)." />
              <img
                src={require("../../../assets/passo_2.png")}
                width={300}
                alt="Token"
              />
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <ListItemText primary="4. Copie o valor exibido no campo 'Token'." />

              <img
                src={require("../../../assets/passo_3.png")}
                width={700}
                alt="Token"
              />
            </Box>
          </ListItem>
        </List>

        <Box>
          <Button
            onClick={onClose}
            target="_blank"
            rel="noreferrer"
            variant="contained"
          >
            Fechar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default TokenDialog;
