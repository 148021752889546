import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CabecalhoTabela from "./TableHeader.component";
import LinhasTabela from "./TableRowsComponent";

const TabelaPedido = ({ itensProps = [], onValoresAtualizados }) => {
  const [itens, setItens] = useState([
    {
      id: 1,
      produto: null,
      codigoFornecedor: "",
      unidade: "",
      quantidade: 0,
      precoUnitario: 0.0,
      ipi: 0.0,
    },
  ]);

  useEffect(() => {
    if (itensProps.length > 0) {
      setItens(itensProps);
      console.log("itensProps", itensProps);
    }
  }, [itensProps]);

  const adicionarItem = () => {
    setItens((prevItens) => [
      ...prevItens,
      {
        id: prevItens.length + 1,
        produto: null,
        codigoFornecedor: "",
        unidade: "",
        quantidade: 0,
        precoUnitario: 0.0,
        ipi: 0.0,
      },
    ]);
  };

  const atualizarItem = (id, campo, valor) => {
    const novosItens = itens.map((item) => {
      if (item.id === id) {
        if (campo === "produto") {
          return { ...item, [campo]: valor || null };
        }
        return { ...item, [campo]: valor };
      }
      return item;
    });

    setItens(novosItens);
    onValoresAtualizados(novosItens);
  };

  const excluirItem = (id) => {
    const novosItens = itens.filter((item) => item.id !== id);
    setItens(novosItens);
    onValoresAtualizados(novosItens);
  };

  return (
    <Grid style={{ marginTop: "80px" }} item xs={12}>
      <Box>
        <Typography style={{ marginBottom: "5px" }}>
          Itens do pedido de compra
        </Typography>
        <Box>
          <CabecalhoTabela />
          <LinhasTabela
            itens={itens}
            atualizarItem={atualizarItem}
            excluirItem={excluirItem}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" marginTop={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={adicionarItem}
          >
            Adicionar Item
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default TabelaPedido;
