import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Fab } from "@mui/material";
import MoreVert from "@mui/icons-material/MoreVertOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useGetEcomerce } from "../../client/hooks/marketplace/marketplace.queries";
import PurchaseOrderDialog from "./components/NewPurchaseOrder.component";
import CustomSnackbar from "../components/Message.Component";

export default function PurchaseOrder() {
  const [performPurchaseOrder, setPerformPurchaseOrder] = useState(true);
  const { data, isLoading, isError, error, refetch } =
    useGetEcomerce(performPurchaseOrder);

  const [alert, setAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [order, setOrder] = useState({
    name: "",
    cnpj: "",
    email: "",
    originState: "",
    anymarketToken: "",
  });

  function openOrderDialog(ordem = null) {
    if (ordem !== null) {
      setOrder(ordem);
    } else {
      // Mock de ordem de compra
      // setOrder({
      //   id: rows.length + 1,
      //   name: `Ordem ${rows.length + 1}`,
      //   cnpj: "12.345.678/0001-90",
      //   email: "fornecedor@example.com",
      //   originState: "SP",
      //   anymarketToken: "mock-token",
      //   itens: [
      //     {
      //       id: 1,
      //       produto: { label: "Produto 1", value: "P001" },
      //       quantidade: 10,
      //       precoUnitario: 50,
      //       ipi: 5,
      //     },
      //     {
      //       id: 2,
      //       produto: { label: "Produto 2", value: "P002" },
      //       quantidade: 5,
      //       precoUnitario: 100,
      //       ipi: 10,
      //     },
      //   ],
      //   detalhesCompra: {
      //     purchaseDate: new Date().toISOString().split("T")[0],
      //     expectedDate: new Date(new Date().setDate(new Date().getDate() + 7))
      //       .toISOString()
      //       .split("T")[0],
      //   },
      // });
    }
    setOpenDialog(true);
  }

  useEffect(() => {
    if (data) {
      setRows(data);
    }
    if (isError) {
      const errorMessage =
        error?.response?.data?.msg ||
        error?.message ||
        "Ocorreu um erro inesperado.";

      setErrorMsg(errorMessage);
      setAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  function loadMsg() {
    setOpenDialog(false);
    refetch();
    setAlert(true);
    setSuccessMsg("Ordem criada com sucesso");
    setPerformPurchaseOrder(true);
  }

  function closeDialog(reload) {
    if (reload) {
      refetch();
    }
    setOpenDialog(false);
    setTimeout(() => setOrder({}), 500);
  }

  return (
    <>
      <CustomSnackbar
        open={alert}
        onClose={() => setAlert(false)}
        severity={isError ? "error" : "success"}
        message={isError ? errorMsg : successMsg}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Razão Social</TableCell>
              <TableCell align="center">Numero</TableCell>
              <TableCell align="center">Data</TableCell>
              <TableCell align="center">Fornecedor</TableCell>
              <TableCell align="center">Observação interna</TableCell>
              <TableCell align="center">Total</TableCell>
              <TableCell align="center">Situação</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#ffd0c6",
                  },
                }}
                onClick={() => openOrderDialog(row)}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center" sx={{ width: 100 }}>
                  <IconButton>
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => openOrderDialog()}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        <AddIcon />
      </Fab>
      <PurchaseOrderDialog
        open={openDialog}
        onClose={closeDialog}
        onSave={loadMsg}
        initialData={order}
        orderNumber={rows.length + 1}
      />
    </>
  );
}
