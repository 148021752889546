import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MaskedInput from "../MaskedInput";
import {
  useCreateEcommerce,
  useUpdateMarketplace,
} from "../../../client/hooks/marketplace/marketplace.queries";
import StatesSelectComponent from "../States.Select.Component";
import CustomSnackbar from "../Message.Component";
import { useQueryClient } from "@tanstack/react-query";
import TokenDialog from "../../../Layouts/components/firstLoginComponents/helpAnyToken.component";

export default function EcommerceDialog({
  open,
  onClose,
  onSave,
  initialData,
}) {
  const queryClient = useQueryClient();
  const [helpOpen, setHelpOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [originstate, setOriginstate] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [anymarketToken, setAnymarketToken] = useState("");

  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errororiginstate, setErrororiginstate] = useState("");
  const [errorCnpj, setErrorCnpj] = useState("");
  const [errorAnymarketToken, setErrorAnymarketToken] = useState("");

  const [performCreateEcomerce, setPerformCreateEcomerce] = useState(false);
  const [performUpdateEcomerce, setPerformUpdateEcommerce] = useState(false);
  const { data, isError, error, isLoading, refetch } = useCreateEcommerce(
    formData,
    performCreateEcomerce
  );
  const {
    data: dataUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
    isLoading: isLoadingUpdate,
    refetch: refetchUpdate,
  } = useUpdateMarketplace(formData, formData.id, performUpdateEcomerce);

  const [alert, setAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [reload, setReload] = useState(undefined);

  function resetQueryData(key) {
    queryClient.resetQueries([key]);
  }

  useEffect(() => {
    if (open) {
      setFormData({});
      setErrorName("");
      setErrorEmail("");
      setErrororiginstate("");
      setErrorCnpj("");
      setErrorAnymarketToken("");
      if (!performCreateEcomerce && !performUpdateEcomerce) {
        if (
          queryClient.getQueryState("ecommerce-create")?.isFeTching ||
          queryClient.getQueryState("ecommerce-update")?.isFeTching
        ) {
          resetQueryData("ecommerce-create");
          resetQueryData("ecommerce-update");
        }
      }
    }
    if (initialData) {
      console.log(initialData);
      setName(initialData.name);
      setEmail(initialData.email);
      setOriginstate(initialData.originstate);
      setCnpj(initialData.cnpj);
      setAnymarketToken(initialData.anymarketToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, initialData]);

  useEffect(() => {
    if (errorName !== "" && name !== "") setErrorName("");
    if (errorEmail !== "" && email !== "") setErrorEmail("");
    if (errororiginstate !== "" && originstate !== "") setErrororiginstate("");
    if (errorCnpj !== "" && cnpj !== "") setErrorCnpj("");
    if (errorAnymarketToken !== "" && anymarketToken !== "")
      setErrorAnymarketToken("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, email, originstate, cnpj, anymarketToken]);

  const validateFields = () => {
    let isValid = true;

    if (!name) {
      setErrorName("Nome é obrigatório.");
      isValid = false;
    } else {
      setErrorName("");
    }

    if (!email) {
      setErrorEmail("Email é obrigatório.");
      isValid = false;
    } else {
      setErrorEmail("");
    }

    if (!originstate) {
      setErrororiginstate("Estado de origem é obrigatório.");
      isValid = false;
    } else {
      setErrororiginstate("");
    }

    if (!cnpj) {
      setErrorCnpj("CNPJ é obrigatório.");
      isValid = false;
    } else {
      setErrorCnpj("");
    }

    if (!anymarketToken) {
      setErrorAnymarketToken("Token AnyMarket é obrigatório.");
      isValid = false;
    } else {
      setErrorAnymarketToken("");
    }

    return isValid;
  };

  function saveChanges() {
    if (!validateFields()) {
      return;
    }
    let tempData = {
      id: initialData.id ? initialData.id : null,
      ...formData,
      name: name,
      email: email,
      originstate: originstate,
      anymarketToken: anymarketToken,
    };
    setFormData(tempData);
    setTimeout(() => {
      if (!isError && !initialData.id) setPerformCreateEcomerce(true);
      if (isError && !initialData.id) {
        refetch();
      }
      if (!isErrorUpdate && initialData.id) setPerformUpdateEcommerce(true);
      if (isErrorUpdate && initialData.id) {
        refetchUpdate();
      }
    }, 300);
  }

  const validarCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;

    const calcularDigito = (base, pesos) => {
      const soma = base
        .split("")
        .reduce((acc, num, i) => acc + parseInt(num) * pesos[i], 0);
      const resto = soma % 11;
      return resto < 2 ? 0 : 11 - resto;
    };

    const primeiroDigito = calcularDigito(
      cnpj.slice(0, 12),
      [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    );
    const segundoDigito = calcularDigito(
      cnpj.slice(0, 13),
      [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    );

    return cnpj.endsWith(`${primeiroDigito}${segundoDigito}`);
  };

  const handleCnpjChange = (e) => {
    const valor = e.target.value;
    setCnpj(valor);

    if (valor.replace(/[^\d]+/g, "").length === 14) {
      if (!validarCNPJ(valor)) {
        setErrorCnpj("CNPJ inválido. Insira um CNPJ válido.");
      } else {
        setErrorCnpj("");
        let tempFormData = { ...formData, cnpj: valor.replace(/[^\d]+/g, "") };
        setFormData(tempFormData);
      }
    } else {
      setErrorCnpj("");
      let tempFormData = { ...formData, cnpj: valor.replace(/[^\d]+/g, "") };
      setFormData(tempFormData);
    }
  };

  useEffect(() => {
    if (isError || isErrorUpdate) {
      const errorMessage =
        error?.response?.data?.msg ||
        error?.message ||
        "Ocorreu um erro inesperado.";
      setErrorMsg(errorMessage);
      setAlert(true);
    }

    if (data && data.id) {
      onSave(data);
    }

    if (dataUpdate && dataUpdate.user.id) {
      setSuccessMsg("Ecommerce atualizado com sucesso");
      setAlert(true);
      setReload(true);
    }
    setPerformCreateEcomerce(false);
    setPerformUpdateEcommerce(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error, data, dataUpdate, errorUpdate, isErrorUpdate]);

  return (
    <>
      <CustomSnackbar
        open={alert}
        onClose={() => setAlert(false)}
        severity={isError ? "error" : "success"}
        message={isError ? errorMsg : successMsg}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderTop: (theme) => `4px solid ${theme.palette.primary.main}`,
            borderRadius: "0 0 8px 8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {initialData.id ? "Editar Marketplace" : "Criar Marketplace"}
          <IconButton
            onClick={() => (reload === true ? onClose({ reload }) : onClose())}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Razão Social"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                error={!!errorName}
                helperText={errorName}
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                fullWidth
                error={!!errorEmail}
                helperText={errorEmail}
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <StatesSelectComponent
                value={originstate}
                onChange={(newValue) => setOriginstate(newValue)}
                size="small"
                name="originstate"
                label="Estado"
                margin="dense"
                error={!!errororiginstate}
                helperText={errororiginstate}
              />
            </Grid>
            <Grid item xs={12}>
              <MaskedInput
                mask="99.999.999/9999-99"
                value={cnpj}
                onChange={handleCnpjChange}
                label={"CNPJ"}
                fullWidth
                size="small"
                margin="dense"
                error={!!errorCnpj}
                helperText={errorCnpj}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                margin="dense"
                label="Any Token"
                name="anymarketToken"
                value={anymarketToken}
                onChange={(e) => setAnymarketToken(e.target.value)}
                fullWidth
                error={!!errorAnymarketToken}
                helperText={errorAnymarketToken}
                required
                size="small"
              />
              <Tooltip title="Informe o token da sua API">
                <IconButton onClick={() => setHelpOpen(true)}>
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onClose(false)}
            color="gray"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={isLoadingUpdate || isLoading}
            onClick={saveChanges}
            color="primary"
            variant="contained"
          >
            Salvar
          </Button>
        </DialogActions>
        <TokenDialog open={helpOpen} onClose={() => setHelpOpen(false)} />
      </Dialog>
    </>
  );
}
