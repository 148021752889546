import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCreateEcommerce,
  useUpdateMarketplace,
} from "../../../client/hooks/marketplace/marketplace.queries";
import CustomSnackbar from "../Message.Component";
import { useQueryClient } from "@tanstack/react-query";

export default function FornecedorDialog({
  open,
  onClose,
  onSave,
  initialData,
}) {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");

  const [performCreateEcomerce, setPerformCreateEcomerce] = useState(false);
  const [performUpdateEcomerce, setPerformUpdateEcommerce] = useState(false);
  const { data, isError, error, isLoading, refetch } = useCreateEcommerce(
    formData,
    performCreateEcomerce
  );
  const {
    data: dataUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
    isLoading: isLoadingUpdate,
    refetch: refetchUpdate,
  } = useUpdateMarketplace(formData, formData.id, performUpdateEcomerce);

  const [alert, setAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  function resetQueryData(key) {
    queryClient.resetQueries([key]);
  }

  useEffect(() => {
    if (open) {
      setFormData({});
      setErrorName("");
      setErrorEmail("");
      if (!performCreateEcomerce && !performUpdateEcomerce) {
        if (
          queryClient.getQueryState("ecommerce-create")?.isFeTching ||
          queryClient.getQueryState("ecommerce-update")?.isFeTching
        ) {
          resetQueryData("ecommerce-create");
          resetQueryData("ecommerce-update");
        }
      }
    }
    if (initialData) {
      setName(initialData.name);
      setEmail(initialData.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, initialData]);

  useEffect(() => {
    if (errorName !== "" && name !== "") setErrorName("");
    if (errorEmail !== "" && email !== "") setErrorEmail("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, email]);

  const validateFields = () => {
    let isValid = true;

    if (!name) {
      setErrorName("Nome é obrigatório.");
      isValid = false;
    } else {
      setErrorName("");
    }

    if (!email) {
      setErrorEmail("Email é obrigatório.");
      isValid = false;
    } else {
      setErrorEmail("");
    }
    return isValid;
  };

  function saveChanges() {
    if (!validateFields()) {
      return;
    }
    let tempData = {
      id: initialData.id ? initialData.id : null,
      ...formData,
      name: name,
      email: email,
    };
    setFormData(tempData);
    setTimeout(() => {
      if (!isError && !initialData.id) setPerformCreateEcomerce(true);
      if (isError && !initialData.id) {
        refetch();
      }
      if (!isErrorUpdate && initialData.id) setPerformUpdateEcommerce(true);
      if (isErrorUpdate && initialData.id) {
        refetchUpdate();
      }
    }, 300);
  }

  useEffect(() => {
    if (isError || isErrorUpdate) {
      const errorMessage =
        error?.response?.data?.msg ||
        error?.message ||
        "Ocorreu um erro inesperado.";
      setErrorMsg(errorMessage);
      setAlert(true);
    }

    if (data && data.id) {
      onSave(data);
    }

    if (dataUpdate && dataUpdate.user.id) {
      setSuccessMsg("Ecommerce atualizado com sucesso");
      setAlert(true);
    }
    setPerformCreateEcomerce(false);
    setPerformUpdateEcommerce(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error, data, dataUpdate, errorUpdate, isErrorUpdate]);

  const resetForm = () => {
    setFormData({});
    setName("");
    setEmail("");
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <>
      <CustomSnackbar
        open={alert}
        onClose={() => setAlert(false)}
        severity={isError ? "error" : "success"}
        message={isError ? errorMsg : successMsg}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderTop: (theme) => `4px solid ${theme.palette.primary.main}`,
            borderRadius: "0 0 8px 8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {initialData && initialData.id
            ? "Editar Fornecedor"
            : "Criar Fornecedor"}
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Razão Social"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                error={!!errorName}
                helperText={errorName}
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                fullWidth
                error={!!errorEmail}
                helperText={errorEmail}
                required
                size="small"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="gray" variant="outlined">
            Cancelar
          </Button>
          <Button
            disabled={isLoadingUpdate || isLoading}
            onClick={saveChanges}
            color="primary"
            variant="contained"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
