import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Tooltip, Fab } from "@mui/material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useGetEcomerce } from "../../client/hooks/marketplace/marketplace.queries";
import CustomSnackbar from "../components/Message.Component";
import FornecedorDialog from "../components/ConfigComponents/fornecedores.dialog.component";

export default function FornecedoresPage() {
  const [performFornecedores, setPerformFornecedores] = useState(true);
  const { data, isLoading, isError, error, refetch } =
    useGetEcomerce(performFornecedores);

  const [alert, setAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [fornecedor, setFornecedor] = useState({
    name: "",
    cnpj: "",
    email: "",
    originState: "",
    anymarketToken: "",
  });

  function openFornecedorDialog(ecomerce = null) {
    if (ecomerce) {
      setFornecedor(ecomerce);
    }
    setOpenDialog(true);
  }

  useEffect(() => {
    if (data) {
      setRows(data);
    }
    if (isError) {
      const errorMessage =
        error?.response?.data?.msg ||
        error?.message ||
        "Ocorreu um erro inesperado.";

      setErrorMsg(errorMessage);
      setAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  function loadMsg() {
    setOpenDialog(false);
    refetch();
    setAlert(true);
    setSuccessMsg("Ecomerce criado com sucesso");
    setPerformFornecedores(true);
  }

  function closeDialog(reload) {
    if (reload) {
      refetch();
    }
    setOpenDialog(false);
    setTimeout(() => setFornecedor({}), 500);
  }

  return (
    <>
      <CustomSnackbar
        open={alert}
        onClose={() => setAlert(false)}
        severity={isError ? "error" : "success"}
        message={isError ? errorMsg : successMsg}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Razão Social</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#ffd0c6",
                  },
                }}
                onClick={() => openFornecedorDialog(row)}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center" sx={{ width: 100 }}>
                  <Tooltip title="Editar fornecedor">
                    <IconButton onClick={() => openFornecedorDialog(row)}>
                      <CreateOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        color="primary"
        aria-label="add"
        onClick={openFornecedorDialog}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        <AddIcon />
      </Fab>
      <FornecedorDialog
        open={openDialog}
        onClose={closeDialog}
        onSave={loadMsg}
        initialData={fornecedor}
      />
    </>
  );
}
