import {
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import MaskedInput from "../../../pages/components/MaskedInput";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import TokenDialog from "./helpAnyToken.component";
import { useCreateEcommerce } from "../../../client/hooks/marketplace/marketplace.queries";
import CustomSnackbar from "../../../pages/components/Message.Component";

export default function CreateEcomStepperComponent({ open, user }) {
  const [alert, setAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [activeStep, setActiveStep] = useState(0);

  const [welcomeText, setWelcomeText] = useState("");
  const headerTitle = useState("Crie seu ecomerce aqui na nossa plataforma!");
  const [cnpj, setCnpj] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    anymarketToken: "",
    originState: "",
    email: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [helpOpen, setHelpOpen] = useState(false);
  const [performCreateEcom, setPerformCreateEcom] = useState(false);
  const { data, isError, error, isLoading } = useCreateEcommerce(
    formData,
    performCreateEcom
  );

  const steps = [
    "Dados da Empresa",
    "Informações de Contato",
    "Obter Token da Any",
  ];

  useEffect(() => {
    if (user) {
      setWelcomeText(`Bem vindo(a) ${user?.name.split(" ")[0]}!`);
    }
  }, [user]);

  const handleNext = () => {
    const errors = validateStep();
    if (Object.keys(errors).length === 0) {
      setActiveStep((prevStep) => prevStep + 1);
      setFormErrors({});
    } else {
      setFormErrors(errors);
    }
  };

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const validateStep = () => {
    const errors = {};
    if (activeStep === 0 && !formData.name) {
      errors.name = "Razão Social é obrigatória.";
    }
    if (activeStep === 1) {
      if (!cnpj) errors.cnpj = "CNPJ é obrigatório.";
      if (!formData.email) errors.email = "Email é obrigatório.";
    }
    if (activeStep === 2 && !formData.token) {
      errors.token = "Token é obrigatório.";
    }
    return errors;
  };

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const validarCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;

    const calcularDigito = (base, pesos) => {
      const soma = base
        .split("")
        .reduce((acc, num, i) => acc + parseInt(num) * pesos[i], 0);
      const resto = soma % 11;
      return resto < 2 ? 0 : 11 - resto;
    };

    const primeiroDigito = calcularDigito(
      cnpj.slice(0, 12),
      [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    );
    const segundoDigito = calcularDigito(
      cnpj.slice(0, 13),
      [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    );

    return cnpj.endsWith(`${primeiroDigito}${segundoDigito}`);
  };

  const handleCnpjChange = (e) => {
    const valor = e.target.value;
    setCnpj(valor);

    if (valor.replace(/[^\d]+/g, "").length === 14) {
      if (!validarCNPJ(valor)) {
        setFormErrors((prev) => ({
          ...prev,
          cnpj: "CNPJ inválido. Insira um CNPJ válido.",
        }));
      } else {
        setFormErrors((prev) => ({ ...prev, cnpj: "" }));
        let tempFormData = { ...formData, cnpj: valor.replace(/[^\d]+/g, "") };
        setFormData(tempFormData);
      }
    } else {
      setFormErrors((prev) => ({ ...prev, cnpj: "" }));
      let tempFormData = { ...formData, cnpj: valor.replace(/[^\d]+/g, "") };
      setFormData(tempFormData);
    }
  };

  const handleCreateEcomerce = async () => {
    setPerformCreateEcom(true);
  };

  useEffect(() => {
    if (isError) {
      const errorMessage =
        error?.response?.data?.msg ||
        error?.message ||
        "Ocorreu um erro inesperado.";
      setErrorMsg(errorMessage);
      setAlert(true);
      setPerformCreateEcom(false);
    }
    if (data) {
      setAlert(true);
      setSuccessMsg(
        "Informações enviadas com sucesso. Agora iremos importar seus dados e isso poderá levar um tempo"
      );
      setPerformCreateEcom(false);
    }
  }, [isError, data, error]);

  return (
    <Dialog fullWidth={true} open={open}>
      <CustomSnackbar
        open={alert}
        onClose={() => setAlert(false)}
        severity={isError ? "error" : "success"}
        message={isError ? errorMsg : successMsg}
      />
      <Container sx={{ padding: "20px" }}>
        <Box
          margin={"0 0 20px 0"}
          gap={2}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {welcomeText}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "700" }}>
            {headerTitle}
          </Typography>
        </Box>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ marginTop: 4 }}>
          {activeStep === 0 && (
            <TextField
              label="Razão Social"
              fullWidth
              size="small"
              value={formData.name}
              onChange={(e) => handleChange("name", e.target.value)}
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
          )}
          {activeStep === 1 && (
            <>
              <MaskedInput
                mask="99.999.999/9999-99"
                value={cnpj}
                onChange={handleCnpjChange}
                label={"CNPJ"}
                fullWidth
                size="small"
                sx={{ marginBottom: 2 }}
                error={!!formErrors.cnpj}
                helperText={formErrors.cnpj}
              />
              <TextField
                label="Email"
                fullWidth
                size="small"
                value={formData.email}
                sx={{ marginBottom: 2 }}
                onChange={(e) => handleChange("email", e.target.value)}
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
              <TextField
                label="Estado de Origem(Sigla)"
                fullWidth
                size="small"
                sx={{ marginBottom: 2 }}
                value={formData.originState}
                onChange={(e) => handleChange("originState", e.target.value)}
                error={!!formErrors.originState}
                helperText={formErrors.originState}
              />
            </>
          )}
          {activeStep === 2 && (
            <Box display="flex" alignItems="center" gap={1}>
              <TextField
                label="anymarketToken"
                fullWidth
                size="small"
                value={formData.anymarketToken}
                onChange={(e) => handleChange("anymarketToken", e.target.value)}
                error={!!formErrors.anymarketToken}
                helperText={formErrors.anymarketToken}
              />
              <IconButton color="primary" onClick={() => setHelpOpen(true)}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 3,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            Voltar
          </Button>
          {activeStep < steps.length - 1 ? (
            <Button variant="contained" onClick={handleNext}>
              Próximo
            </Button>
          ) : (
            <Button
              disabled={
                !!formErrors.token || formData.token === "" || isLoading
              }
              variant="contained"
              color="success"
              onClick={handleCreateEcomerce}
            >
              Finalizar
            </Button>
          )}
        </Box>
      </Container>
      <TokenDialog open={helpOpen} onClose={() => setHelpOpen(false)} />
    </Dialog>
  );
}
