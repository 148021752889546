import React from "react";
import { Box, Typography } from "@mui/material";

const CabecalhoTabela = () => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      style={{
        paddingTop: "10px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box flex="0.8" textAlign="center">
        <Typography variant="body2" fontWeight="bold">
          #
        </Typography>
      </Box>
      <Box flex="5" textAlign="left">
        <Typography variant="body2" fontWeight="bold">
          Item
        </Typography>
      </Box>
      <Box flex="2.9" textAlign="left">
        <Typography variant="body2" fontWeight="bold">
          Código
        </Typography>
      </Box>
      <Box flex="2" textAlign="left">
        <Typography variant="body2" fontWeight="bold">
          Un
        </Typography>
      </Box>
      <Box flex="2" textAlign="left">
        <Typography variant="body2" fontWeight="bold">
          Qtde
        </Typography>
      </Box>
      <Box flex="2" textAlign="left">
        <Typography variant="body2" fontWeight="bold">
          Preço Un
        </Typography>
      </Box>
      <Box flex="2" textAlign="left">
        <Typography variant="body2" fontWeight="bold">
          IPI %
        </Typography>
      </Box>
      <Box flex="2" textAlign="center">
        <Typography variant="body2" fontWeight="bold">
          Preço Total
        </Typography>
      </Box>
      <Box flex="1" textAlign="center"></Box>
      {/* <Box flex="1" textAlign="center"></Box> */}
      <Box flex="1" textAlign="center"></Box>
    </Box>
  );
};

export default CabecalhoTabela;
