import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  // Tooltip,
  List,
  ListItem,
} from "@mui/material";
// import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import WarehouseIcon from "@mui/icons-material/Warehouse";

const LinhasTabela = ({ itens, atualizarItem, excluirItem }) => {
  const [showMenu, setShowMenu] = useState(false);
  const produtos = [
    { label: "Produto 1", value: "P001" },
    { label: "Produto 2", value: "P002" },
    { label: "Produto 3", value: "P003" },
  ];

  const formatNumber = (value) =>
    value ? parseFloat(value).toFixed(2).replace(".", ",") : "0,00";

  const handlePositiveNumberInput = (value) => {
    const onlyNumbers = value.replace(/[^0-9]/g, "");
    return onlyNumbers ? parseFloat(onlyNumbers) / 100 : 0;
  };
  const calcularPrecoTotal = (quantidade, precoUnitario) =>
    (quantidade * precoUnitario).toFixed(2);

  useEffect(() => {
    console.log("itens", itens);
  }, [itens]);

  return (
    <Box marginTop={2}>
      {itens.map((item, index) => (
        <Box
          key={item.id}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          {/* Índice */}
          <Box
            flex="0.8"
            textAlign="center"
            style={{
              border: "1px solid #ccc",
              backgroundColor: "#ff2f0075",
              borderRadius: "6px",
              marginRight: 5,
            }}
          >
            <Typography variant="h6">{index + 1}</Typography>
          </Box>

          {/* Item */}
          <Box
            flex="5"
            style={{
              borderLeft: "1px solid #ccc",
              borderTop: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
            }}
          >
            <Autocomplete
              style={{ paddingLeft: "10px" }}
              options={produtos}
              getOptionLabel={(option) =>
                option ? `${option.label} (${option.value})` : ""
              }
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              value={item.produto || null}
              onChange={(event, newValue) => {
                atualizarItem(item.id, "produto", newValue || null);
              }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    style: { border: "none" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Box>

          {/* Código */}
          <Box
            flex="2.9"
            style={{
              borderLeft: "1px solid #ccc",
              borderTop: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
            }}
          >
            <TextField
              style={{ paddingLeft: "10px" }}
              variant="standard"
              size="small"
              value={item.codigoFornecedor}
              onChange={(e) =>
                atualizarItem(item.id, "codigoFornecedor", e.target.value)
              }
              InputProps={{
                disableUnderline: true,
                style: { border: "none" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>

          {/* Unidade */}
          <Box
            flex="2"
            style={{
              borderLeft: "1px solid #ccc",
              borderTop: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
            }}
          >
            <TextField
              style={{ paddingLeft: "10px" }}
              variant="standard"
              size="small"
              value={item.unidade}
              onChange={(e) =>
                atualizarItem(item.id, "unidade", e.target.value)
              }
              InputProps={{
                disableUnderline: true,
                style: { border: "none" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>

          {/* Quantidade */}
          <Box
            flex="2"
            style={{
              borderLeft: "1px solid #ccc",
              borderTop: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
            }}
          >
            <TextField
              style={{ paddingLeft: "10px" }}
              variant="standard"
              size="small"
              value={item.quantidade || ""}
              onChange={(e) => {
                const value =
                  parseInt(e.target.value.replace(/[^0-9]/g, ""), 10) || 0;
                atualizarItem(item.id, "quantidade", value);
              }}
              InputProps={{
                disableUnderline: true,
                style: { border: "none" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>

          {/* Preço Unitário */}
          <Box
            flex="2"
            style={{
              borderLeft: "1px solid #ccc",
              borderTop: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
            }}
          >
            <TextField
              style={{ paddingLeft: "10px" }}
              variant="standard"
              size="small"
              value={formatNumber(item.precoUnitario)}
              onChange={(e) => {
                const value = handlePositiveNumberInput(e.target.value);
                atualizarItem(item.id, "precoUnitario", value);
              }}
              InputProps={{
                disableUnderline: true,
                style: { border: "none" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>

          {/* IPI */}
          <Box
            flex="2"
            style={{
              borderLeft: "1px solid #ccc",
              borderTop: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
            }}
          >
            <TextField
              style={{ paddingLeft: "10px" }}
              variant="standard"
              size="small"
              value={formatNumber(item.ipi)}
              onChange={(e) => {
                const value = handlePositiveNumberInput(e.target.value);
                atualizarItem(item.id, "ipi", value);
              }}
              InputProps={{
                disableUnderline: true,
                style: { border: "none" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>

          {/* Preço Total */}
          <Box
            flex="2"
            textAlign="center"
            style={{
              borderLeft: "1px solid #ccc",
              borderTop: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
            }}
          >
            <Typography
              style={{
                height: "29px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              R$ {calcularPrecoTotal(item.quantidade, item.precoUnitario)}
            </Typography>
          </Box>

          {/* Botão de Info */}
          {/* <Box
            flex="1"
            textAlign="center"
            style={{
              borderLeft: "1px solid #ccc",
              borderTop: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
            }}
          >
            <Tooltip title="Informações adicionais">
              <IconButton sx={{ height: "29px" }}>
                <InfoIcon sx={{ fontSize: "20px", color: "#0eb2e8" }} />
              </IconButton>
            </Tooltip>
          </Box> */}

          {/* Botão de Tooltip */}
          <Box
            flex="1"
            textAlign="center"
            style={{
              position: "relative",
              borderLeft: "1px solid #ccc",
              borderTop: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
            }}
          >
            <IconButton
              sx={{ height: "29px" }}
              onMouseEnter={() => setShowMenu(true)}
              onMouseLeave={() => setShowMenu(false)}
            >
              <WarehouseIcon sx={{ fontSize: "20px", color: "#FD947C" }} />
            </IconButton>

            {/* Menu */}
            {showMenu && (
              <Box
                onMouseEnter={() => setShowMenu(true)}
                onMouseLeave={() => setShowMenu(false)}
                sx={{
                  position: "absolute",
                  bottom: "100%",
                  right: 0,
                  zIndex: 10,
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  minWidth: "200px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Todos os depósitos
                </Typography>
                <List dense>
                  <ListItem>
                    <Typography variant="body2">
                      Estoque atual: 161,000
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body2">
                      Estoque mínimo: 25,000
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body2">
                      Estoque máximo: 40,000
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body2">
                      Estoque depois da compra: 162,000
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            )}
          </Box>

          {/* Botão de Exclusão */}
          <Box
            flex="1"
            textAlign="center"
            style={{
              borderLeft: "1px solid #ccc",
              borderTop: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
              borderRight: "1px solid #ccc",
            }}
          >
            <IconButton
              sx={{ height: "29px" }}
              onClick={() => excluirItem(item.id)}
            >
              <DeleteIcon sx={{ fontSize: "20px", color: "red" }} />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default LinhasTabela;
