import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Menus } from "../router/Menus";
import React, { useEffect, useState } from "react";
import { useGetUser } from "../client/hooks/users/user.queries";
import NotificationHeader from "./components/headers/notification.header.component";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import {
  Alert,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Toolbar,
  Typography,
} from "@mui/material";
import UserAppBarComponent from "./components/user/user.appbar.component";
import UserProfileDialogComponent from "./components/user/user.profile.component";
import CreateEcomStepperComponent from "./components/firstLoginComponents/CreateEconStepper.component.jsx";
import LoadingScreen from "../pages/Loading.page.jsx";
import CustomSnackbar from "../pages/components/Message.Component.jsx";
import WavingHand from "@mui/icons-material/WavingHandOutlined";
import Slide from "@mui/material/Slide";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

export default function MainLayout() {
  const theme = createTheme({
    cssVariables: true,
    palette: {
      primary: {
        main: "#ff8266",
      },
      background: {
        default: "#f9ecec",
      },
    },
    typography: {
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });
  const [open, setOpen] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState(null);
  let indexedLocation = localStorage.getItem("location");
  const [selectedMenu, setSelectedMenu] = useState(indexedLocation || 0);
  const [selectedSubmenu, setSelectedSubmenu] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const { data, isLoading, isError, error, refetch } = useGetUser();
  const [alert, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [firtsLogin, setFirtsLogin] = useState(false);

  const [userName, setUserName] = useState("");
  const [showWelcome, setShowWelcome] = useState(false);

  const [openDialogEcom, setOpenDialogEcom] = useState(false);

  useEffect(() => {
    if (isError) {
      const errorMessage =
        error?.response?.data?.msg ||
        error?.message ||
        "Ocorreu um erro inesperado.";

      setErrorMsg(errorMessage);
      setAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    const organization_id = localStorage.getItem("organization_id");
    if (organization_id === "") {
      setFirtsLogin(true);
      setOpenDialogEcom(true);
    }
  }, []);

  useEffect(() => {
    if (selectedMenu >= 0) {
      localStorage.setItem(
        "location",
        JSON.stringify({ menu: selectedMenu, submenu: selectedSubmenu })
      );
    }
  }, [selectedMenu, selectedSubmenu]);

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("firstLogin") === "true" && data) {
        const firstName = data.name.split(" ")[0];
        setUserName(firstName);
        setShowWelcome(true);
        // localStorage.setItem("firstLogin", false);
      }
    }, 1000);
    const storedLocation = localStorage.getItem("location");
    if (storedLocation) {
      const { menu, submenu } = JSON.parse(storedLocation);
      setSelectedMenu(menu);
      if (submenu !== null && submenu !== undefined) {
        setSelectedSubmenu(submenu);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  function handleToggleMenu(m, index) {
    if (!m.children) {
      setSelectedMenu(index);
      setSelectedSubmenu(null);
      localStorage.setItem("selectedMenu", index);
      localStorage.removeItem("selectedSubmenu");
    } else {
      setOpen(true);
      setExpandedMenu(expandedMenu === index ? null : index);
    }
  }
  function handleSubmenuClick(mIndex, submenuIndex) {
    setSelectedMenu(mIndex);
    setSelectedSubmenu(submenuIndex);
    localStorage.setItem("selectedMenu", mIndex);
    localStorage.setItem("selectedSubmenu", submenuIndex);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function handleLogout() {
    localStorage.clear();
    window.location.replace("/login");
  }

  function handleDialogClose() {
    setOpenProfile(false);
    refetch();
  }
  const handleCloseWelcome = () => {
    setShowWelcome(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <Snackbar
          autoHideDuration={3000}
          open={showWelcome}
          TransitionComponent={Slide}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseWelcome}
        >
          <Alert
            icon={false}
            variant="filled"
            sx={{ width: "100%", backgroundColor: "#FF7322" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2">Bem vindo, {userName}!</Typography>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseWelcome}
              >
                <WavingHand />
              </IconButton>
            </Box>
          </Alert>
        </Snackbar>
        <CustomSnackbar
          open={alert}
          onClose={() => setAlert(false)}
          severity={isError ? "error" : "success"}
          message={isError ? errorMsg : ""}
        />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={[open && { display: "none" }]}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              sx={{ ...(!open && { display: "none" }) }}
              onClick={handleDrawerClose}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon sx={{ color: "white" }} />
              )}
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                border: "2px solid rgba(255,255,255,.1)",
                borderRadius: "4px",
                color: "white",
                fontFamily: "sans-serif",
                fontWeight: "700",
                marginLeft: "15px",
                padding: "0 5px 0 5px",
                cursor: "default",
              }}
              noWrap
            >
              {Menus[selectedMenu]?.children
                ? Menus[selectedMenu]?.title +
                  " / " +
                  Menus[selectedMenu]?.children[selectedSubmenu]?.title
                : Menus[selectedMenu]?.title}
            </Typography>
            <Box sx={{ marginLeft: "auto", marginRight: "1%" }}>
              <NotificationHeader />
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar>
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                ...(!open && { display: "none" }),
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <img
                src={require("../assets/wala.png")}
                style={{ width: "100%", height: "85px" }}
                alt="Logo"
              />
            </IconButton>
          </Toolbar>
          <Divider />
          <List sx={{ height: "100%" }}>
            {Menus.map((m, index) => (
              <React.Fragment key={index}>
                <ListItem
                  disablePadding
                  sx={{
                    display: "block",
                    backgroundColor:
                      (open && selectedMenu === index && !m.children) ||
                      (!open && selectedMenu === index)
                        ? theme.palette.primary.main
                        : "inherit",
                  }}
                >
                  <ListItemButton
                    onClick={() => handleToggleMenu(m, index)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {m.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={m.title}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    <ListItemIcon
                      style={m.children ? {} : { display: "none" }}
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {open ? (
                        expandedMenu === index ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      ) : null}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>

                {m.children && expandedMenu === index && open && (
                  <List component="div" disablePadding>
                    {m.children.map((child, childIndex) => (
                      <ListItemButton
                        key={childIndex}
                        onClick={() => handleSubmenuClick(index, childIndex)}
                        sx={{
                          pl: 4,
                          minHeight: 40,
                          justifyContent: open ? "initial" : "center",
                          backgroundColor:
                            open &&
                            selectedMenu === index &&
                            selectedSubmenu === childIndex
                              ? theme.palette.primary.main
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedMenu !== index ||
                              selectedSubmenu !== childIndex
                                ? "#0000000a"
                                : theme.palette.primary.main,
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{ minWidth: 0, mr: open ? 3 : "auto" }}
                        >
                          {child.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={child.title}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                )}
              </React.Fragment>
            ))}
            <ListItem
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                position: "absolute",
                bottom: 0,
                margin: "0 0 1% 0",
              }}
            >
              <UserAppBarComponent
                open={open}
                onAction={setOpenProfile}
                user={data}
                isLoading={isLoading}
              />

              {open ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <ListItemButton onClick={handleLogout}>
                    <LogoutIcon
                      onClickCapture={handleLogout}
                      sx={{
                        padding: "4px",
                        borderRadius: "4px",
                        transition: "80ms all",
                        cursor: "pointer",
                        scale: "1.1",
                        color: "#ff8266",
                        ":hover": {
                          transitionDelay: "60ms",
                          background: "#ff826617",
                        },
                      }}
                    />
                    <Typography sx={{ ml: 1 }}>Sair</Typography>
                  </ListItemButton>
                </Box>
              ) : (
                <LogoutIcon
                  onClickCapture={handleLogout}
                  sx={{
                    padding: "4px",
                    marginBottom: "5px",
                    borderRadius: "4px",
                    transition: "80ms all",
                    cursor: "pointer",
                    scale: "1.1",
                    color: "#ff8266",
                    ":hover": {
                      transitionDelay: "60ms",
                      background: "#ff826617",
                    },
                  }}
                />
              )}
            </ListItem>
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {firtsLogin && selectedMenu !== 3 ? (
            <LoadingScreen />
          ) : Menus[selectedMenu]?.children ? (
            Menus[selectedMenu]?.children[selectedSubmenu]?.component
          ) : (
            Menus[selectedMenu]?.component
          )}
        </Box>
      </Box>
      {openProfile && (
        <UserProfileDialogComponent
          open={openProfile}
          setOpen={handleDialogClose}
          user={data}
          isLoading={isLoading}
        />
      )}
      {openDialogEcom && (
        <CreateEcomStepperComponent
          open={openDialogEcom}
          isLoading={isLoading}
          user={data}
        />
      )}
    </ThemeProvider>
  );
}
