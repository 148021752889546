import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { usePutUser } from "../../../client/hooks/users/user.queries";
import CustomSnackbar from "../../../pages/components/Message.Component";

export default function UserProfileDialogComponent({
  open,
  setOpen,
  user,
  isLoadingProps,
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [avatar, setAvatar] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [performEditUser, setPerformEditUser] = useState(false);
  const [editedUser, setEditedUser] = useState({});
  const [headerName, setHeaderName] = useState("");
  const [headerEmail, setHeaderEmail] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  const { data, isError, error, isLoading, refetch } = usePutUser(
    editedUser,
    performEditUser
  );

  const [alert, setAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorAvatar, setErrorAvatar] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");

  function validatePasswords() {
    let isValid = true;

    if (oldPassword && newPassword) {
      isValid = true;
    } else {
      if (!oldPassword && newPassword) {
        setErrorPassword("Senha atual é obrigatória.");
        isValid = false;
      } else {
        setErrorPassword("");
      }

      if (!newPassword && oldPassword) {
        setErrorNewPassword("Nova senha é obrigatória.");
        isValid = false;
      } else {
        setErrorNewPassword("");
      }
    }

    return isValid;
  }

  function validateFields() {
    let isValid = true;

    if (!name) {
      setErrorName("Nome é obrigatório.");
      isValid = false;
    } else {
      setErrorName("");
    }

    if (!email) {
      setErrorEmail("Email é obrigatório.");
      isValid = false;
    } else {
      setErrorEmail("");
    }

    if (!phone) {
      setErrorPhone("Telefone é obrigatório.");
      isValid = false;
    } else {
      setErrorPhone("");
    }

    if (!avatar) {
      setErrorAvatar("Avatar é obrigatório.");
      isValid = false;
    } else {
      setErrorAvatar("");
    }

    return isValid;
  }

  function formateDate(date) {
    let dateWithOffset = moment(date).add(3, "hours");
    let dateFormated = dateWithOffset.format("DD/MM/YYYY HH:mm:ss");
    return dateFormated;
  }

  useEffect(() => {
    if (isError) {
      const errorMessage =
        error?.response?.data?.msg ||
        error?.message ||
        "Ocorreu um erro inesperado.";

      setErrorMsg(errorMessage);
      setAlert(true);
    } else if (data?.success) {
      setSuccessMsg("Usuario atualizado com sucesso!");
      setAlert(true);
    }
  }, [isError, error, data]);

  useEffect(() => {
    setName(user.name);
    setEmail(user.email);
    setPhone(user.phone);
    setAvatar(user.avatar);
    setHeaderName(user.name);
    setHeaderEmail(user.email);
    setUpdatedAt(formateDate(user.updatedAt));
  }, [user]);

  useEffect(() => {
    if (data?.success) {
      setName(data.user.name);
      setEmail(data.user.email);
      setPhone(data.user.phone);
      setAvatar(data.user.avatar);
      setHeaderName(data.user.name);
      setHeaderEmail(data.user.email);
      setUpdatedAt(formateDate(data.user.updatedAt));
    }
    setPerformEditUser(false);
  }, [data]);

  function saveChanges() {
    if (oldPassword || newPassword) {
      if (!validatePasswords()) {
        return;
      }
    }
    if (!validateFields()) {
      return;
    }
    let changedUser = {
      name: name,
      email: email,
      phone: phone,
      avatar: avatar,
    };

    if (oldPassword !== "" || newPassword !== "") {
      changedUser = {
        ...changedUser,
        oldPassword,
        newPassword,
      };
    }
    setEditedUser(changedUser);
    setTimeout(() => {
      if (!isError) setPerformEditUser(true);
      if (isError) refetch();
    }, 50);
  }

  return (
    <Dialog fullWidth={true} open={open}>
      <CustomSnackbar
        open={alert}
        onClose={() => setAlert(false)}
        severity={isError ? "error" : "success"}
        message={isError ? errorMsg : successMsg}
      />
      <Container sx={{ padding: "20px" }}>
        <Box
          margin={"0 0 20px 0"}
          display={"flex"}
          gap={2}
          alignItems={"center"}
        >
          {isLoadingProps ? (
            <Skeleton animation="wave" sx={{ height: 100, boxShadow: 2 }}>
              <Avatar sx={{ width: 80, boxShadow: 2 }} />
            </Skeleton>
          ) : (
            <Avatar
              alt={user.name}
              variant="rounded"
              src={user.avatar ? user.avatar : "googli.dom"}
              //onClickCapture={() => onAction(true)}
              sx={{ width: 80, height: 80, boxShadow: 2 }}
            />
          )}

          <Divider orientation="vertical" variant="middle" flexItem />
          <Box
            sx={{
              color: "gray",
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
            }}
          >
            {isLoadingProps ? (
              <>
                <Skeleton animation="wave" width={180} height={30} />
                <Skeleton animation="wave" width={180} height={30} />
              </>
            ) : (
              <>
                <Typography variant="h5">{headerName}</Typography>
                <Typography variant="h6">{headerEmail}</Typography>
              </>
            )}
          </Box>
          <Box marginLeft={"auto"} marginBottom={"auto"}>
            <CloseIcon
              sx={{
                padding: "1px",
                color: "gray",
                ":hover": {
                  cursor: "pointer",
                  background: "#ff82660f",
                  borderRadius: "50px",
                },
              }}
              onClick={() => setOpen(false)}
            />
          </Box>
        </Box>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: 4,
            background: "#FFF",
            height: "50%",
            width: "auto",
            padding: "20px",
            border: "1px solid #8080802e",
            borderRadius: "4px",
          }}
        >
          <Box sx={{ height: "auto", width: "100%" }}>
            <TextField
              label={"Name"}
              value={name}
              onChange={(e) => setName(e.target.value)}
              size="small"
              fullWidth
              error={!!errorName}
              helperText={errorName}
            />
          </Box>
          <Box sx={{ height: "auto", width: "100%" }}>
            <TextField
              label={"Email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="small"
              fullWidth
              error={!!errorEmail}
              helperText={errorEmail}
            />
          </Box>
          <Box sx={{ height: "auto", width: "100%" }}>
            <TextField
              label={"Telefone"}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              size="small"
              fullWidth
              error={!!errorPhone}
              helperText={errorPhone}
            />
          </Box>
          <Box sx={{ height: "auto", width: "100%" }}>
            <TextField
              label={"Avatar"}
              value={avatar}
              onChange={(e) => setAvatar(e.target.value)}
              size="small"
              fullWidth
              error={!!errorAvatar}
              helperText={errorAvatar}
            />
          </Box>

          <Divider orientation="horizontal" variant="middle" flexItem />

          <Box
            sx={{
              height: "auto",
              width: "100%",
              display: "flex",
              columnGap: 2,
            }}
          >
            <TextField
              label="Senha Atual"
              value={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
                if (e.target.value) setErrorPassword("");
              }}
              size="small"
              fullWidth={true}
              error={!!errorPassword}
              helperText={errorPassword}
            />
            <TextField
              label="Nova Senha"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                if (e.target.value) setErrorNewPassword("");
              }}
              size="small"
              fullWidth={true}
              error={!!errorNewPassword}
              helperText={errorNewPassword}
            />
          </Box>
        </Container>
        <Box
          padding={"5px 0 0 0"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            sx={{
              fontSize: "13px",
              color: "gray",
              fontStyle: "italic",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            {`Ultima atualização em ${updatedAt.split(" ")[0]} as ${updatedAt.split(" ")[1]}...`}
          </Typography>
          <Button
            disabled={isLoading}
            onClick={saveChanges}
            variant="text"
            sx={{ fontWeight: 600 }}
          >
            SALVAR
          </Button>
        </Box>
      </Container>
    </Dialog>
  );
}
