import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Box, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const TotaisCompra = ({ itens, onTotaisAtualizados }) => {
  const [totalIcmsStInput, setTotalIcmsStInput] = useState("");
  const [descontoInput, setDescontoInput] = useState("");
  const [freteInput, setFreteInput] = useState("");
  const [totais, setTotais] = useState({
    numeroItens: 0,
    somaQuantidades: 0,
    totalProdutos: 0,
    totalIcmsSt: 0,
    totalIpi: 0,
    desconto: 0,
    frete: 0,
    totalPedido: 0,
  });

  // Atualiza os totais sempre que os itens forem alterados
  useEffect(() => {
    const numeroItens = itens.length;
    const somaQuantidades = itens.reduce(
      (sum, item) => sum + (item.quantidade || 0),
      0
    );
    const totalProdutos = itens.reduce(
      (sum, item) => sum + (item.quantidade * item.precoUnitario || 0),
      0
    );
    const totalIpi = itens.reduce((sum, item) => sum + (item.ipi || 0), 0);

    const novosTotais = {
      numeroItens,
      somaQuantidades,
      totalProdutos,
      totalIpi,
      totalPedido:
        totalProdutos +
        totais.totalIcmsSt +
        totalIpi -
        (totalProdutos * (totais.desconto || 0)) / 100 +
        (totais.frete || 0),
    };

    setTotais((prev) => ({ ...prev, ...novosTotais }));
    if (onTotaisAtualizados) {
      onTotaisAtualizados(novosTotais); // Emite os totais atualizados para o componente pai
    }
  }, [
    itens,
    totais.totalIcmsSt,
    totais.desconto,
    totais.frete,
    onTotaisAtualizados,
  ]);

  const handlePositiveNumberInput = (value) => {
    const numericValue = value.replace(/[^0-9]/g, "");
    return numericValue ? parseFloat(numericValue) / 100 : 0;
  };

  const handleIcmsStChange = (value) => {
    const totalIcmsSt = value || 0;

    const novosTotais = {
      ...totais,
      totalIcmsSt,
      totalPedido:
        totais.totalProdutos +
        totalIcmsSt +
        totais.totalIpi -
        (totais.totalProdutos * (totais.desconto || 0)) / 100 +
        totais.frete,
    };

    setTotais(novosTotais);
    setTotalIcmsStInput(value);

    if (onTotaisAtualizados) {
      onTotaisAtualizados(novosTotais);
    }
  };

  const handleDescontoChange = (value) => {
    const desconto = value || 0;

    const novosTotais = {
      ...totais,
      desconto,
      totalPedido:
        totais.totalProdutos +
        totais.totalIcmsSt +
        totais.totalIpi -
        (totais.totalProdutos * desconto) / 100 +
        totais.frete,
    };

    setTotais(novosTotais);
    setDescontoInput(value);

    if (onTotaisAtualizados) {
      onTotaisAtualizados(novosTotais);
    }
  };

  const handleFreteChange = (value) => {
    const frete = value || 0;

    const novosTotais = {
      ...totais,
      frete,
      totalPedido:
        totais.totalProdutos +
        totais.totalIcmsSt +
        totais.totalIpi -
        (totais.totalProdutos * (totais.desconto || 0)) / 100 +
        frete,
    };

    setTotais(novosTotais);
    setFreteInput(value);

    if (onTotaisAtualizados) {
      onTotaisAtualizados(novosTotais);
    }
  };

  const formatNumber = (value) =>
    value ? parseFloat(value).toFixed(2).replace(".", ",") : "0,00";

  return (
    <>
      <Grid container spacing={2}>
        {/* Totais dos Produtos */}
        <Grid item xs={4}>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <Typography style={{ marginBottom: "5px", marginRight: 4 }}>
              Totais dos Produtos
            </Typography>
            <Box
              textAlign="center"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <Tooltip title="Subtotal de todos os itens sem descontos">
                <InfoIcon sx={{ fontSize: "20px", color: "#0eb2e8" }} />
              </Tooltip>
            </Box>
          </Box>
          <TextField
            variant="outlined"
            size="small"
            value={totais.totalProdutos.toFixed(2).replace(".", ",")}
            disabled
            fullWidth
            InputProps={{
              style: { textAlign: "right", backgroundColor: "#f5f5f5" },
            }}
          />
        </Grid>

        {/* Total ICMS ST */}
        <Grid item xs={2}>
          <Typography style={{ marginBottom: "5px" }}>Total ICMS ST</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={formatNumber(totalIcmsStInput)}
            onChange={(e) => {
              const value = handlePositiveNumberInput(e.target.value);
              handleIcmsStChange(value);
            }}
            InputProps={{
              style: { textAlign: "right", border: "none" },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* Total do IPI */}
        <Grid item xs={2}>
          <Typography style={{ marginBottom: "5px" }}>Total do IPI</Typography>
          <TextField
            variant="outlined"
            size="small"
            value={totais.totalIpi.toFixed(2).replace(".", ",")}
            disabled
            fullWidth
            InputProps={{
              style: { textAlign: "right", backgroundColor: "#f5f5f5" },
            }}
          />
        </Grid>

        {/* Total do Pedido */}
        <Grid item xs={4}>
          <Typography style={{ marginBottom: "5px" }}>
            Total do Pedido
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            value={totais.totalPedido.toFixed(2).replace(".", ",")}
            disabled
            fullWidth
            InputProps={{
              style: { textAlign: "right", backgroundColor: "#f5f5f5" },
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* Numero de itens */}
        <Grid item xs={2}>
          <Typography style={{ marginBottom: "5px", marginRight: 4 }}>
            Nº de itens
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            value={totais.numeroItens}
            disabled
            fullWidth
            InputProps={{
              style: { textAlign: "right", backgroundColor: "#f5f5f5" },
            }}
          />
        </Grid>

        {/* Soma das quantidades */}
        <Grid item xs={2}>
          <Typography style={{ marginBottom: "5px" }}>
            Soma das qtdes
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={totais.somaQuantidades}
            disabled
            InputProps={{
              style: { textAlign: "right", backgroundColor: "#f5f5f5" },
            }}
          />
        </Grid>

        {/* Desconto */}
        <Grid item xs={2}>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <Typography style={{ marginBottom: "5px", marginRight: 4 }}>
              Desconto
            </Typography>
            <Box
              textAlign="center"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <Tooltip title="Ex: 3,55%">
                <InfoIcon sx={{ fontSize: "20px", color: "#0eb2e8" }} />
              </Tooltip>
            </Box>
          </Box>
          <TextField
            variant="outlined"
            size="small"
            value={formatNumber(descontoInput)}
            onChange={(e) => {
              const value = handlePositiveNumberInput(e.target.value);
              handleDescontoChange(value);
            }}
            fullWidth
            InputProps={{
              style: { textAlign: "right" },
            }}
          />
        </Grid>

        {/* Frete */}
        <Grid item xs={2}>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <Typography style={{ marginBottom: "5px", marginRight: 4 }}>
              Frete
            </Typography>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <Tooltip title="Valor do frete">
                <InfoIcon sx={{ fontSize: "20px", color: "#0eb2e8" }} />
              </Tooltip>
            </Box>
          </Box>
          <TextField
            variant="outlined"
            size="small"
            value={formatNumber(freteInput)}
            onChange={(e) => {
              const value = handlePositiveNumberInput(e.target.value);
              handleFreteChange(value);
            }}
            fullWidth
            InputProps={{
              style: { textAlign: "right" },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TotaisCompra;
