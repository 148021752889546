import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  IconButton,
  Typography,
  Box,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCreateEcommerce,
  useGetEcomerce,
  useUpdateMarketplace,
} from "../../../client/hooks/marketplace/marketplace.queries";
import { useQueryClient } from "@tanstack/react-query";
import CustomSnackbar from "../../components/Message.Component";
import FornecedorDialog from "../../components/ConfigComponents/fornecedores.dialog.component";
import TabelaPedido from "./TableOrder.component";
import TotaisCompra from "./TotaisCompra.component";
import DetailsPurchase from "./DetailsPurchase.component";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

export default function PurchaseOrderDialog({
  open,
  onClose,
  onSave,
  initialData,
  orderNumber,
}) {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({});
  const [tableItensOrder, setTableItensOrder] = useState({ itens: [] });
  const [fornecedor, setFornecedor] = useState(null);
  const [tempFornecedor, setTempFornecedor] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [performFornecedores, setPerformFornecedores] = useState(true);
  const [internObservation, setInternObservation] = useState("");
  const [externObservation, setExternObservation] = useState("");
  const {
    data: fornecedoresData,
    isLoading: isLoadingFornecedores,
    isError: isErrorFornecedores,
    error: errorFornecedores,
  } = useGetEcomerce(performFornecedores);
  const [performCreateOrder, setPerformCreateOrder] = useState(false);
  const [performUpdateOrder, setPerformUpdateOrder] = useState(false);
  const { data, isError, error, isLoading, refetch } = useCreateEcommerce(
    formData,
    performCreateOrder
  );

  const {
    data: dataUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
    isLoading: isLoadingUpdate,
    refetch: refetchUpdate,
  } = useUpdateMarketplace(formData, formData.id, performUpdateOrder);

  const [alert, setAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [options, setOptions] = useState([
    { name: "Fornecedor 1", email: "teste@teste1.com" },
    { name: "Fornecedor 2", email: "teste@teste2.com" },
    { name: "Fornecedor 3", email: "teste@teste3.com" },
  ]);

  function resetQueryData(key) {
    queryClient.resetQueries([key]);
  }

  useEffect(() => {
    if (open) {
      setFormData({});
      if (!performCreateOrder && !performUpdateOrder) {
        if (
          //ajustar para  limpar as query de ordem
          queryClient.getQueryState("ecommerce-create")?.isFeTching ||
          queryClient.getQueryState("ecommerce-update")?.isFeTching
        ) {
          resetQueryData("ecommerce-create");
          resetQueryData("ecommerce-update");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (open && initialData) {
      setTableItensOrder({ itens: initialData.itens || [] });
      setFornecedor(initialData.name || "");
      setFormData(initialData.detalhesCompra || {});
    }
  }, [open, initialData]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // use effect para limpar os erros
  }, []);

  const validateFields = () => {
    let isValid = true;

    // função para validar os campos
    return isValid;
  };

  function saveChanges() {
    if (!validateFields()) {
      return;
    }
    let tempData = {
      id: initialData.id ? initialData.id : null,
      ...formData,
    };
    setFormData(tempData);
    setTimeout(() => {
      if (!isError && !initialData.id) setPerformCreateOrder(true);
      if (isError && !initialData.id) {
        refetch();
      }
      if (!isErrorUpdate && initialData.id) setPerformUpdateOrder(true);
      if (isErrorUpdate && initialData.id) {
        refetchUpdate();
      }
    }, 300);
  }

  const handleAddFornecedor = (isEdit = false) => {
    if (isEdit) {
      setTempFornecedor(fornecedor || null);
    } else {
      setTempFornecedor(null);
    }
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setFornecedor(tempFornecedor || null);
    setTempFornecedor(null);
    console.log(tempFornecedor);
    console.log(fornecedor);
  };

  function loadMsg(data) {
    setOpenDialog(false);
    refetch();
    setAlert(true);
    setSuccessMsg("Fornecedor criado com sucesso");
    setPerformFornecedores(true);
    setFornecedor("fornecedor que foi criado");
  }

  useEffect(() => {
    if (isError || isErrorUpdate || isErrorFornecedores) {
      const errorMessage =
        error?.response?.data?.msg ||
        error?.message ||
        errorUpdate?.response?.data?.msg ||
        errorUpdate?.message ||
        errorFornecedores?.response?.data?.msg ||
        errorFornecedores?.message ||
        "Ocorreu um erro inesperado.";
      setErrorMsg(errorMessage);
      setAlert(true);
    }

    if (data && data.id) {
      onSave(data);
    }

    if (dataUpdate && dataUpdate.user.id) {
      setSuccessMsg("Pedido de compra atualizado com sucesso");
      setAlert(true);
    }
    setPerformCreateOrder(false);
    setPerformUpdateOrder(false);

    if (fornecedoresData && !isLoadingFornecedores) {
      // setOptions(fornecedoresData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isError,
    error,
    data,
    dataUpdate,
    errorUpdate,
    isErrorUpdate,
    //
    fornecedoresData,
    isErrorFornecedores,
  ]);

  const resetForm = () => {
    setFormData({});
    setTableItensOrder({ itens: [] });
    setFornecedor("");
  };

  const handleClose = (reload = false) => {
    resetForm();
    onClose(reload);
  };

  return (
    <>
      <CustomSnackbar
        open={alert}
        onClose={() => setAlert(false)}
        severity={isError ? "error" : "success"}
        message={isError ? errorMsg : successMsg}
      />
      <Dialog
        open={open}
        fullScreen
        PaperProps={{
          sx: {
            borderTop: (theme) => `4px solid ${theme.palette.primary.main}`,
            borderRadius: "0 0 8px 8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {initialData.id ? "Editar Pedido de Compra" : "Novo Pedido de Compra"}
          <IconButton onClick={() => handleClose(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Box>
                <Typography style={{ marginBottom: "5px" }}>
                  Fornecedor
                </Typography>
                <Autocomplete
                  freeSolo
                  options={options}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.email === value.email
                  }
                  value={fornecedor}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setFornecedor({ name: newValue, email: "" });
                    } else if (newValue && typeof newValue === "object") {
                      setFornecedor(newValue);
                    } else {
                      setFornecedor(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id="fornecedor"
                      name="fornecedor"
                      label="Selecione o fornecedor"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => handleAddFornecedor(false)}
                              >
                                <AddCircleOutlineIcon
                                  style={{
                                    color: "#FD947C",
                                    display: !fornecedor?.name ? "" : "none",
                                  }}
                                />
                              </IconButton>
                            </InputAdornment>
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => handleAddFornecedor(true)}
                              >
                                <CreateOutlinedIcon
                                  style={{
                                    color: "#FD947C",
                                    display: !fornecedor?.name
                                      ? "none"
                                      : "block",
                                  }}
                                />
                              </IconButton>
                            </InputAdornment>
                          </>
                        ),
                      }}
                    />
                  )}
                  componentsProps={{
                    clearIndicator: null,
                  }}
                />
              </Box>
            </Grid>
            <TabelaPedido
              itensProps={tableItensOrder.itens || []}
              onValoresAtualizados={(novosItens) =>
                setTableItensOrder((prevData) => ({
                  ...prevData,
                  itens: novosItens,
                }))
              }
            />
            <Grid item xs={12}>
              <Typography style={{ marginTop: "50px" }}>
                Totais da compra
              </Typography>
              <TotaisCompra
                itens={tableItensOrder.itens || []}
                onTotaisAtualizados={(totaisAtualizados) => {
                  setFormData((prev) => ({
                    ...prev,
                    totais: totaisAtualizados,
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ marginTop: "50px" }}>
                Detalhes da compra
              </Typography>
              <DetailsPurchase
                orderNumber={orderNumber}
                onDetalhesAtualizados={(detalhesAtualizados) => {
                  setFormData((prev) => ({
                    ...prev,
                    detalhesCompra: detalhesAtualizados,
                  }));
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              gap={2}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  style={{ marginTop: "50px", marginBottom: 20 }}
                >
                  Observação interna
                </Typography>
                <TextField
                  multiline
                  fullWidth
                  rows={4}
                  value={internObservation}
                  onChange={(e) => setInternObservation(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  style={{ marginTop: "50px", marginBottom: 20 }}
                >
                  Observação externa
                </Typography>
                <TextField
                  multiline
                  fullWidth
                  rows={4}
                  value={externObservation}
                  onChange={(e) => setExternObservation(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose(false)}
            color="gray"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={isLoadingUpdate || isLoading}
            onClick={saveChanges}
            color="primary"
            variant="contained"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <FornecedorDialog
        open={openDialog}
        onClose={closeDialog}
        onSave={(savedFornecedor) => loadMsg(savedFornecedor)}
        initialData={tempFornecedor}
      />
    </>
  );
}
