import ReactInputMask from "react-input-mask";
import React from "react";
import { TextField } from "@mui/material";

const MaskedInput = React.forwardRef((props, ref) => {
  const { mask, value, onChange, ...rest } = props;

  return (
    <ReactInputMask mask={mask} value={value} onChange={onChange} {...rest}>
      {(inputProps) => (
        <TextField {...inputProps} inputRef={ref} fullWidth size="small" />
      )}
    </ReactInputMask>
  );
});

export default MaskedInput;
