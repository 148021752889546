import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

const messages = [
  { text: "Treinando gatos para digitar...", img: "🐱" },
  { text: "Consultando a bola de cristal...", img: "🔮" },
  { text: "Acordando o servidor... shhh!", img: "😴" },
  { text: "Preparando um café virtual...", img: "☕" },
  { text: "Verificando se Marte está alinhado...", img: "🔭" },
  { text: "Pedindo ajuda aos alienígenas...", img: "👽" },
  { text: "Enrolando o carregamento de propósito...", img: "⏳" },
  { text: "Cadastrando unicórnios...", img: "🦄" },
  { text: "Fazendo um backup do backup...", img: "💾" },
  { text: "Chamando um programador ninja...", img: "🥷" },
  { text: "Removendo bugs imaginários...", img: "🐛" },
  { text: "Formatando a internet...", img: "🌐" },
  { text: "Tentando lembrar a senha...", img: "🔐" },
  { text: "Desfazendo o que acabou de ser feito...", img: "↩️" },
  { text: "Calculando o infinito...", img: "♾️" },
  { text: "Programando com mágica...", img: "✨" },
  { text: "Conectando dois fios soltos...", img: "🔧" },
  { text: "Cuidando dos gremlins...", img: "👾" },
  { text: "Trocando pilhas virtuais...", img: "🔋" },
  { text: "Cuidando dos gatos de Schrödinger...", img: "😺" },
  { text: "Fingindo que está tudo bem...", img: "😅" },
  { text: "Caçando pacotes perdidos...", img: "📦" },
  { text: "Reorganizando o caos...", img: "🎯" },
  { text: "Limpando poeira digital...", img: "🧹" },
  { text: "Pintando os pixels...", img: "🎨" },
  { text: "Atualizando a Matrix...", img: "💻" },
  { text: "Consultando a IA...", img: "🤖" },
  { text: "Mandando spam para o servidor...", img: "📧" },
  { text: "Dando um tapa técnico...", img: "👋" },
];

const LoadingScreen = () => {
  const [currentMessage, setCurrentMessage] = useState(messages[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * messages.length);
      setCurrentMessage(messages[randomIndex]);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        height: "95%",
        backgroundColor: "#f0f0f0",
        textAlign: "center",
      }}
    >
      <CircularProgress size={80} sx={{ mb: 2 }} />
      <Typography variant="h4" sx={{ mb: 1 }}>
        {currentMessage.img}
      </Typography>
      <Typography variant="h6">{currentMessage.text}</Typography>
    </Box>
  );
};

export default LoadingScreen;
