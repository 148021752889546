import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Box, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const DetailsPurchase = ({ orderNumber, onDetalhesAtualizados }) => {
  const [purchaseDate, setPurchaseDate] = useState("");
  const [expectedDate, setExpectedDate] = useState("");

  useEffect(() => {
    // Define a data de compra como a data atual no formato ISO ao carregar o componente
    const today = new Date().toISOString().split("T")[0];
    setPurchaseDate(today);
    if (onDetalhesAtualizados) {
      onDetalhesAtualizados({ purchaseDate: today, expectedDate: today });
    }
  }, [onDetalhesAtualizados]);

  const handlePurchaseDateChange = (e) => {
    const newDate = e.target.value;
    setPurchaseDate(newDate);
    if (onDetalhesAtualizados) {
      onDetalhesAtualizados({ purchaseDate: newDate, expectedDate });
    }
  };

  const handleExpectedDateChange = (e) => {
    const newDate = e.target.value;
    setExpectedDate(newDate);
    if (onDetalhesAtualizados) {
      onDetalhesAtualizados({ purchaseDate, expectedDate: newDate });
    }
  };

  return (
    <Grid container spacing={2}>
      {/* Número da Ordem */}
      <Grid item xs={4}>
        {" "}
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Typography style={{ marginBottom: "5px" }}>
            Número da Ordem
          </Typography>
          <Box
            textAlign="center"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <Tooltip title="Numero de controle de pedido">
              <InfoIcon sx={{ fontSize: "20px", color: "#0eb2e8" }} />
            </Tooltip>
          </Box>
        </Box>
        <TextField
          variant="outlined"
          size="small"
          value={orderNumber}
          disabled
          fullWidth
          InputProps={{
            style: { textAlign: "right", backgroundColor: "#f5f5f5" },
          }}
        />
      </Grid>

      {/* Data de Compra */}
      <Grid item xs={4}>
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Typography style={{ marginBottom: "5px" }}>
            Data de Compra
          </Typography>
          <Box
            textAlign="center"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <Tooltip title="Data que foi feita a compra">
              <InfoIcon sx={{ fontSize: "20px", color: "#0eb2e8" }} />
            </Tooltip>
          </Box>
        </Box>
        <TextField
          type="date"
          variant="outlined"
          size="small"
          value={purchaseDate}
          onChange={handlePurchaseDateChange}
          fullWidth
          InputProps={{
            style: { textAlign: "right" },
          }}
        />
      </Grid>

      {/* Data Prevista */}
      <Grid item xs={4}>
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Typography style={{ marginBottom: "5px" }}>Data Prevista</Typography>
          <Box
            textAlign="center"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <Tooltip title="Data prevista de entrega">
              <InfoIcon sx={{ fontSize: "20px", color: "#0eb2e8" }} />
            </Tooltip>
          </Box>
        </Box>
        <TextField
          type="date"
          variant="outlined"
          size="small"
          value={expectedDate}
          onChange={handleExpectedDateChange}
          fullWidth
          InputProps={{
            style: { textAlign: "right" },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DetailsPurchase;
