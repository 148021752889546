import { useQuery } from "@tanstack/react-query";
import { api } from "../../config";

 function useGetFilters(enabled) {
    return useQuery({
        queryFn: async () => {
            const res = await api.get(`/marketplace/filters`);
            return res.data;
        },
        queryKey: ['order-filters'],
        enabled,
        retry: false,
        refetchOnWindowFocus: false
    })
}

function useCreateEcommerce(data, exec) {
    return useQuery({
        queryFn: async () => {
            const res = await api.post(`/marketplace`, data);
            return res.data;
        },
        queryKey: ['ecommerce-create'],
        enabled: exec,
        retry: false,
        refetchOnWindowFocus: false
    })
}

function useGetEcomerce() {
    return useQuery({
        queryFn: async () => {
            const res = await api.get(`/marketplace`);
            return res.data;
        },
        queryKey: ['marketplace'],
        retry: false,
        refetchOnWindowFocus: false
    })
}

function useUpdateMarketplace(data, id, exec) {
    return useQuery({
        queryFn: async () => {
            const res = await api.put(`/marketplace/${id}`, data);
            return res.data;
        },
        queryKey: ['marketplace-update'],
        enabled: exec,
        retry: false,
        refetchOnWindowFocus: false
    })
}


export {
    useGetFilters,
    useCreateEcommerce,
    useGetEcomerce,
    useUpdateMarketplace
}