import React from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Autocomplete,
} from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";

const FilterComponent = ({
  showFilters,
  setShowFilters,
  estoqueAtual,
  estoqueOC,
  setEstoqueAtual,
  setEstoqueOC,
  filterText,
  setFilterText,
  handleResetFilters,
  canaisVenda,
  selectedChannel,
  setSelectedChannel,
}) => {
  const handleEstoqueAtualChange = (field, value) => {
    setEstoqueAtual((prev) => ({
      ...prev,
      [field]: value ? Math.max(0, Math.min(10000, parseInt(value))) : "",
    }));
  };

  const handleEstoqueOCChange = (field, value) => {
    setEstoqueOC((prev) => ({
      ...prev,
      [field]: value ? Math.max(0, Math.min(2500, parseInt(value))) : "",
    }));
  };

  return (
    <Box
      style={{
        backgroundColor: "white",
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        borderRadius: 8,
        padding: "10px",
      }}
    >
      {/* Linha principal dos filtros */}
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <TextField
          style={{
            width: "40.8%",
            marginLeft: 20,
            backgroundColor: "#fd947c78",
            borderRadius: 8,
            marginRight: 20,
          }}
          size="small"
          id="outlined-basic"
          label="Filtrar"
          variant="outlined"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />

        <Autocomplete
          options={canaisVenda}
          getOptionLabel={(option) => option.label}
          value={selectedChannel}
          onChange={(event, newValue) => setSelectedChannel(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Canais de Venda"
              variant="outlined"
              size="small"
            />
          )}
          size="small"
          style={{ width: "25%" }}
        />

        <Box style={{ marginLeft: "auto", display: "flex" }}>
          <IconButton onClick={() => setShowFilters(!showFilters)}>
            <FilterAltOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => setShowFilters(false)}>
            <FilterAltOffOutlinedIcon />
          </IconButton>
          <IconButton onClick={handleResetFilters}>
            <RotateLeftOutlinedIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Linha de filtros adicionais */}
      <Box
        style={{
          backgroundColor: "white",
          height: 60,
          display: showFilters ? "flex" : "none",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: 8,
          marginTop: 10,
          marginLeft: 20,
        }}
      >
        {/* Estoque Atual */}
        <Box style={{ width: "20%", marginRight: 20 }}>
          <Typography variant="body2" gutterBottom>
            Estoque Atual
          </Typography>
          <Box display="flex" gap={1}>
            <TextField
              label="Min"
              type="number"
              value={estoqueAtual.min}
              onChange={(e) => handleEstoqueAtualChange("min", e.target.value)}
              size="small"
              variant="outlined"
              inputProps={{ min: 0, max: 10000 }}
              style={{ width: "50%" }}
            />
            <TextField
              label="Max"
              type="number"
              value={estoqueAtual.max}
              onChange={(e) => handleEstoqueAtualChange("max", e.target.value)}
              size="small"
              variant="outlined"
              inputProps={{ min: 0, max: 10000 }}
              style={{ width: "50%" }}
            />
          </Box>
        </Box>

        {/* Estoque OC */}
        <Box style={{ width: "20%" }}>
          <Typography variant="body2" gutterBottom>
            Estoque OC
          </Typography>
          <Box display="flex" gap={1}>
            <TextField
              label="Min"
              type="number"
              value={estoqueOC.min}
              onChange={(e) => handleEstoqueOCChange("min", e.target.value)}
              size="small"
              variant="outlined"
              inputProps={{ min: 0, max: 2500 }}
              style={{ width: "50%" }}
            />
            <TextField
              label="Max"
              type="number"
              value={estoqueOC.max}
              onChange={(e) => handleEstoqueOCChange("max", e.target.value)}
              size="small"
              variant="outlined"
              inputProps={{ min: 0, max: 2500 }}
              style={{ width: "50%" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterComponent;
