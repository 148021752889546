import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import {
  useCreatetUserAffiliate,
  useUpdateUserAffiliate,
} from "../../../client/hooks/users/user.queries";
import MaskedInput from "../MaskedInput";
import { useQueryClient } from "@tanstack/react-query";
import CustomSnackbar from "../Message.Component";

export default function UserDialogComponent({
  open,
  onCloseDialog,
  user,
  showAlert,
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [avatar, setAvatar] = useState("");
  const [password, setPassword] = useState("");

  const [userDialog, setUserDialog] = useState({});
  const [performSendUser, setPerformSendUser] = useState(false);
  const [performUpdateUser, setPerformUpdateUser] = useState(false);

  const queryClient = useQueryClient();
  const { data, isError, error, isLoading, refetch } = useCreatetUserAffiliate(
    userDialog,
    performSendUser
  );

  const {
    data: dataUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
    isLoading: isLoadingUpdate,
    refetch: refetchUpdate,
  } = useUpdateUserAffiliate(userDialog, user.id, performUpdateUser);

  const [alert, setAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [reload, setReload] = useState(undefined);

  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorAvatar, setErrorAvatar] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  function resetQueryData(key) {
    queryClient.resetQueries([key]);
  }

  function saveChanges() {
    if (!validateFields()) {
      return;
    }

    let userUpdated = {
      name: name,
      email: email,
      phone: phone,
      avatar: avatar,
      password: password,
    };
    setUserDialog(userUpdated);
    setTimeout(() => {
      if (!isError && !user.id) setPerformSendUser(true);
      if (isError && !user.id) {
        refetch();
      }
      if (!isErrorUpdate && user.id) setPerformUpdateUser(true);
      if (isErrorUpdate && user.id) {
        refetchUpdate();
      }
    }, 200);
  }

  useEffect(() => {
    if (open) {
      setErrorName("");
      setErrorEmail("");
      setErrorPhone("");
      setErrorAvatar("");
      setErrorPassword("");
      setPassword("");
      setReload(false);
      if (!performSendUser && !performUpdateUser) {
        if (
          queryClient.getQueryState("users-create-affiliate")?.isFetching ||
          queryClient.getQueryState("users-update-affiliate")?.isFetching
        ) {
          resetQueryData("users-create-affiliate");
          resetQueryData("users-update-affiliate");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setPhone(user.phone);
      setAvatar(user.avatar);
    }
  }, [open, user]);

  useEffect(() => {
    if (isError || isErrorUpdate) {
      const errorMessage =
        error?.response?.data?.msg ||
        error?.message ||
        "Ocorreu um erro inesperado.";
      setErrorMsg(errorMessage);
      setAlert(true);
    }

    if (data && data.id) {
      showAlert();
    }

    if (dataUpdate && dataUpdate.user.id) {
      setSuccessMsg("Usuário atualizado com sucesso");
      setAlert(true);
      setReload(true);
    }
    setPerformSendUser(false);
    setPerformUpdateUser(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error, data, dataUpdate, errorUpdate, isErrorUpdate]);

  function validateFields() {
    let isValid = true;

    if (!name) {
      setErrorName("Nome é obrigatório.");
      isValid = false;
    } else {
      setErrorName("");
    }

    if (!email) {
      setErrorEmail("Email é obrigatório.");
      isValid = false;
    } else {
      setErrorEmail("");
    }

    if (!phone) {
      setErrorPhone("Telefone é obrigatório.");
      isValid = false;
    } else {
      setErrorPhone("");
    }

    if (!avatar) {
      setErrorAvatar("Avatar é obrigatório.");
      isValid = false;
    } else {
      setErrorAvatar("");
    }

    return isValid;
  }

  return (
    <Dialog fullWidth={true} open={open}>
      <CustomSnackbar
        open={alert}
        onClose={() => setAlert(false)}
        severity={isError ? "error" : "success"}
        message={isError ? errorMsg : successMsg}
      />
      <Container sx={{ padding: "20px" }}>
        <Box
          margin={"0 0 20px 0"}
          display={"flex"}
          gap={2}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box marginLeft={"auto"} marginBottom={"auto"}>
            <CloseIcon
              sx={{
                padding: "1px",
                color: "gray",
                ":hover": {
                  cursor: "pointer",
                  background: "#ff82660f",
                  borderRadius: "50px",
                },
              }}
              onClick={() =>
                reload === true ? onCloseDialog({ reload }) : onCloseDialog()
              }
            />
          </Box>
        </Box>
        <Box
          margin={"0 0 20px 0"}
          display={"flex"}
          gap={2}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Avatar
            alt={user && user.name ? user.name : "Novo Usuario"}
            variant="rounded"
            src={user && user.avatar ? user.avatar : "googli.dom"}
            //onClickCapture={() => onAction(true)}
            sx={{ width: 80, height: 80, boxShadow: 2, borderRadius: "50px" }}
          />
        </Box>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: 4,
            background: "#FFF",
            height: "50%",
            width: "auto",
            padding: "20px",
            border: "1px solid #8080802e",
            borderRadius: "4px",
          }}
        >
          <Box sx={{ height: "auto", width: "100%" }}>
            <TextField
              label={"Name do usuário"}
              value={name}
              onChange={(e) => setName(e.target.value)}
              size="small"
              fullWidth
              error={!!errorName}
              helperText={errorName}
            />
          </Box>
          <Box sx={{ height: "auto", width: "100%" }}>
            <TextField
              label={"Email do usuário"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="small"
              fullWidth
              error={!!errorEmail}
              helperText={errorEmail}
            />
          </Box>
          <Box sx={{ height: "auto", width: "100%" }}>
            <MaskedInput
              mask="(99) 99999-9999"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              label={"Telefone do usuário"}
              size="small"
              fullWidth
              error={!!errorPhone}
              helperText={errorPhone}
            />
          </Box>
          <Box sx={{ height: "auto", width: "100%" }}>
            <TextField
              label={"Link da foto do usuário"}
              value={avatar}
              onChange={(e) => setAvatar(e.target.value)}
              size="small"
              fullWidth
              error={!!errorAvatar}
              helperText={errorAvatar}
            />
          </Box>

          <Divider
            sx={{ display: user && user.id ? "none" : "flex" }}
            orientation="horizontal"
            variant="middle"
            flexItem
          />

          <Box
            sx={{
              height: "auto",
              width: "100%",
              display: user && user.id ? "none" : "flex",
              columnGap: 2,
            }}
          >
            <TextField
              label="Senha do usuário"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                if (e.target.value) setErrorPassword("");
              }}
              size="small"
              fullWidth={true}
              error={!!errorPassword}
              helperText={errorPassword}
            />
          </Box>
        </Container>
        <Box
          padding={"5px 0 0 0"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"end"}
        >
          <Button
            onClick={() =>
              reload === true ? onCloseDialog({ reload }) : onCloseDialog()
            }
            variant="outlined"
            sx={{ color: "grey", borderColor: "gray", marginRight: "10px" }}
          >
            CANCELAR
          </Button>
          <Button
            disabled={isLoading || isLoadingUpdate}
            onClick={saveChanges}
            variant="outlined"
            sx={{ fontWeight: 600 }}
          >
            SALVAR
          </Button>
        </Box>
      </Container>
    </Dialog>
  );
}
